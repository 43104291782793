<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Historia Menstrual</span> </div>
            <div class="card-body">
                <table class="table table-bordered">
                    <tr>
                        <th>Fecha ultima Menstruacion</th>
                        <td>{{data.fecha_ultima_menstruacion}}</td>
                    </tr>
                     <tr>
                        <th>Ciclo Regular</th>
                        <td>{{data.ciclo_regular}}</td>
                    </tr>
                    <tr>
                        <th>Transtornos menstruales</th>
                        <td>{{data.transtornos_menstruales}}</td>
                    </tr>
                     <tr>
                        <th>Transtorno menstrual</th>
                        <td>{{data.transtorno_menstrual}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props:['idHistoria','idUsuario','finalizada','data'],
}
</script>
