<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Toma de Muestra</span> </div>
            <div class="card-body">
                <table class="table table-bordered">
                    <tr>
                        <th>Fecha de la Toma de muestra</th>
                        <td>{{data.fecha_lectura}}</td>
                    </tr>
                     <tr>
                        <th>No. Placa</th>
                        <td>{{data.numero_placa}}</td>
                    </tr>
                    <tr>
                        <th>Tipo Muestra</th>
                        <td>{{data.tipo_muestra}}</td>
                    </tr>
                    <tr>
                        <th>Tipo Tamizaje</th>
                        <td>{{data.tipo_tamizaje}}</td>
                    </tr>
                    <tr>
                        <th>Observaciones</th>
                        <td>{{data.observaciones}}</td>
                    </tr>
                    <tr>
                        <th>Aspectos</th>
                        <td>
                            <ul>
                                <li v-for="item in data.aspectos" :key="item.id">
                                    {{item.aspecto}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props:['idHistoria','idUsuario','finalizada','data'],
}
</script>
