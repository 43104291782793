<template>
    <main>
        <div class=" " >
            <div class=" ">
                <div class="form-row">
                    <div class="form-group col-md-6 "  >
                        <label class="form-label" >Fecha Toma muestra</label>
                        <input type="date" class="form-control" v-model="fecha_lectura" :class="{'is-invalid':$v.fecha_lectura.$error && $v.fecha_lectura.$dirty}">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Numero de Placa</label>
                        <input type="number" v-model="numero" class="form-control" :class="{'is-invalid':$v.numero.$error && $v.numero.$dirty}">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Aspecto del Cuello</label>
                        <select class="form-control" v-model="item.aspecto_cuello" :class="{'is-invalid':$v.item.aspecto_cuello.$error && $v.item.aspecto_cuello.$dirty}">
                            <option :value="item" v-for="(item,i) in cuello" :key="i">{{item}}</option>
                        </select>
                    </div>
                     
                    <div class="mt-4 py-2 col-md-6  ">
                        
                        <button class="btn btn-primary " @click="agregar()">
                            <i class="fas fa-plus"></i>&nbsp;Agregar
                        </button>
                    </div>
                </div>
               
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tr class="table-primary">
                            <th>Aspecto del cuello </th>
                            <th></th>
                        </tr>
                        <tr v-for="(item,i) in items" :key="i" >
                            <td>{{item.aspecto_cuello}}</td>
                            <td>
                                <button class="btn btn-outline-danger"  @click="eliminar()">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>                                
                    </table>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="form-label">Tipo de tamizaje</label>
                        <select class="form-control" v-model="tipo_tamizaje_seleccionado" :class="{'is-invalid':$v.tipo_tamizaje_seleccionado.$error && $v.tipo_tamizaje_seleccionado.$dirty}">
                            <option :value="item" v-for="(item,i) in tipo_tamizaje" :key="i">{{item}}</option>
                        </select>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="form-label">Tipo de Muestra</label>
                        <select class="form-control" v-model="tipo_muestra_seleccionado" :class="{'is-invalid':$v.tipo_muestra_seleccionado.$error && $v.tipo_muestra_seleccionado.$dirty}">
                            <option :value="item" v-for="(item,i) in tipo_muestra" :key="i">{{item}}</option>
                        </select>
                    </div>

                    <div class="form-group col-md-12">
                        <label class="form-label">Observaciones</label>
                        <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="observaciones"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import {required,minLength} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';

import Toast from '../../../../components/common/utilities/toast';
import tomaMuestrasService from "../../../../services/tomaMuestrasService";
export default {
    props:['idHistoria','idUsuario','finalizada'],
     
    data() {
        return{
            cuello:['AUSENTE','SANO','ATROFICO','CONGESTIVO','SANGRANTE','EROSIONADO','ULCERADO','POLIPO','LESION VISIBLE','ECTOPIA'],
            tipo_tamizaje: ['CITOLOGIA','TECNICA DE INSPECCION VISUAL','ADN/VPH'],
            tipo_muestra: ['CONVENCIONAL','PREPARACION EN BASE LIQUIDA'],
            
            item:{
               aspecto_cuello:{},
            },
            items: [],
            numero:'',
            fecha_lectura:'',
            tipo_muestra_seleccionado:'',
            tipo_tamizaje_seleccionado:'',
            observaciones:'',
            id_historia_toma:''
        }
    },
    validations (){
        return {
            item:{ 
               aspecto_cuello:{required},
            },
            numero:{required},
            fecha_lectura:{required},
            tipo_muestra_seleccionado:{required},
            tipo_tamizaje_seleccionado:{required},
            items: {
                required,
                minLength: minLength(1),
            }
        }
    },
    methods: {
        agregar() {

            this.$v.item.$touch();

            if (this.$v.item.$invalid) {
                return;
            }

            this.items.push({...this.item});
        },
        eliminar(index){
            this.items.splice(index, 1);
        },
        async save() {
           try {
                
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar un aspecto de cuello'
                    });
                    return;
                }
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                let muestras={
                    id_historia: this.idHistoria,
                    numero_placa:this.numero,
                    fecha_lectura:this.fecha_lectura,
                    tipo_muestra_seleccionado:this.tipo_muestra_seleccionado,
                    tipo_tamizaje_seleccionado:this.tipo_tamizaje_seleccionado,
                    observaciones:this.observaciones,
                    cuello:this.items,
                }

                this.LoaderSpinnerShow();

                if(this.id_historia_toma!==''){
                    muestras.id=this.id_historia_toma;
                    await tomaMuestrasService.update(muestras);
                }else{
                    await tomaMuestrasService.store(muestras);
                    const response=await tomaMuestrasService.showByHc(this.idHistoria);
                    this.id_historia_toma=response.data.id;
                }
                 
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
               this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
           }
        }
    },
    watch:{
        finalizada: async function (value){

            if(value==='N'){
                const response=await tomaMuestrasService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                     this.observaciones=response.data.observaciones;
                    this.fecha_lectura=response.data.fecha_lectura;
                    this.numero=response.data.numero_placa;
                    this.tipo_tamizaje_seleccionado=response.data.tipo_tamizaje;
                    this.tipo_muestra_seleccionado=response.data.tipo_muestra;
                    this.id_historia_toma=response.data.id;
                    //this.items = response.data.items;
                     this.items = response.data.aspectos.map(x=>{
                        return {
                            aspecto_cuello:x.aspecto,
                        }
                    });
                
                }
             
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
           const response=await tomaMuestrasService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                     this.observaciones=response.data.observaciones;
                    this.fecha_lectura=response.data.fecha_lectura;
                    this.numero=response.data.numero_placa;
                    this.tipo_tamizaje_seleccionado=response.data.tipo_tamizaje;
                    this.tipo_muestra_seleccionado=response.data.tipo_muestra;
                    this.id_historia_toma=response.data.id;
                    //this.items = response.data.items;
                    this.items = response.data.aspectos.map(x=>{
                        return {
                            aspecto_cuello:x.aspecto,
                        }
                    });
                
                }
        }
    }
  
}
</script>
