<template>
    <main>
        <div class=" " >
            <div class=" ">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label class="form-label">Fecha ultima Menstruacion</label>
                        <input type="date" v-model="fecha_ultima_menstruacion" class="form-control">
                    </div>
                    <div class="form-group col-md-4">
                        <label class="form-label">Ciclo Regular</label>
                        <select v-model="ciclo_regular" class="form-control" :class="{'is-invalid':$v.ciclo_regular.$error && $v.ciclo_regular.$dirty}">
                            <option value="S">S</option>
                            <option value="N">N</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="form-label">Transtornos menstruales</label>
                        <select v-model="transtornos_menstruales" class="form-control" :class="{'is-invalid':$v.transtornos_menstruales.$error && $v.transtornos_menstruales.$dirty}">
                            <option value="S">S</option>
                            <option value="N">N</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="form-label">Transtorno menstrual</label>
                        <textarea class="form-control" v-model="transtorno_menstrual" cols="30" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historialMenstrualService from '../../../../services/historialMenstrualService';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';

import Toast from '../../../../components/common/utilities/toast';
export default {
    props:['idHistoria','idUsuario','finalizada'],
     
    data() {
        return{
        
            fecha_ultima_menstruacion:'',
            transtornos_menstruales:'',
            transtorno_menstrual:'',
            ciclo_regular:'',
            id_historia_menstrual:'',
            
        }
    },
    validations (){
        return {
           // fecha_ultima_menstruacion:{required},
            transtornos_menstruales:{required},
            ciclo_regular:{required},
        }
    },
    methods: {
        async save() {
            try {

                this.$v.$touch();

                if(this.$v.$invalid){
                return
                }

                let historia_menstrual = {
                    id_historia:this.idHistoria,  
                    ciclo_regular:this.ciclo_regular,
                    transtornos_menstruales:this.transtornos_menstruales,
                    transtorno_menstrual: this.transtorno_menstrual,
                    fecha_ultima_menstruacion:this.fecha_ultima_menstruacion
                };

                this.LoaderSpinnerShow();

                if(this.id_historia_menstrual!==''){
                    historia_menstrual['id']=this.id_historia_menstrual;
                    await historialMenstrualService.update(historia_menstrual);
                }else{
                    await historialMenstrualService.store(historia_menstrual);
                    const response=await historialMenstrualService.showByHc(this.idHistoria);
                    this.id_historia_menstrual=response.data.id;
                }

                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
    },
   watch:{
        finalizada: async function (value){
            if(value==='N'){
             const response=await historialMenstrualService.showByHc(this.idHistoria);
             if(!isEmpty(response.data)){
                this.fecha_ultima_menstruacion=response.data.fecha_ultima_menstruacion;
                this.transtornos_menstruales=response.data.transtornos_menstruales;
                this.transtorno_menstrual=response.data.transtorno_menstrual;
                this.ciclo_regular=response.data.ciclo_regular;
                this.id_historia_menstrual=response.data.id;
             }
             
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response=await historialMenstrualService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.fecha_ultima_menstruacion=response.data.fecha_ultima_menstruacion;
                this.transtornos_menstruales=response.data.transtornos_menstruales;
                this.transtorno_menstrual=response.data.transtorno_menstrual;
                this.ciclo_regular=response.data.ciclo_regular;
                this.id_historia_menstrual=response.data.id;
             }
        }
    }
}
</script>
