<template>
  <main>
    <div class="card mt-2 shadow-none" v-if="isLoading">
      <div class="card-body">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="false" />
          <content-placeholders-text :lines="3" />
        </content-placeholders>
      </div>
    </div>
    <div v-else>
      <div class="py-2" v-if="resumen && resumen.motivo_consulta">
        <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.historia_menstrual">
        <indexHistoriaMenstrualShow :idHistoria="idHistoria" :idUsuario="idUsuario" :data="resumen.historia_menstrual">
        </indexHistoriaMenstrualShow>
      </div>
      <div class="py-2" v-if="resumen && resumen.toma_muestra">
        <tomaMuestraShow :idHistoria="idHistoria" :idUsuario="idUsuario" :data="resumen.toma_muestra">
        </tomaMuestraShow>
      </div>
    </div>
  </main>

</template>

<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import indexHistoriaMenstrualShow from "../../base/baseHistoriaMenstrual/indexHistoriaMenstrualShow";
import tomaMuestraShow from "../../base/baseTomaMuestras/tomaMuestraShow.vue";
import historiaClinicaService from "../../../../services/historiaClinicaService";

export default {
  props: ['idHistoria', 'idUsuario', 'usuario'],
  components: {
    motivoConsultaShow,
    indexHistoriaMenstrualShow,
    tomaMuestraShow
  },
  data() {
    return {
      resumen: {},
      isLoading: true
    };
  },

  async created() {
    this.isLoading = true;
    const response = await historiaClinicaService.resumenHc(this.idHistoria);
    this.resumen = response.data;
    this.isLoading = false;
  },
}
</script>

<style scoped>
.resumen-container {
  height: 300px;
  overflow-y: scroll
}
</style>